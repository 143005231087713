import { FC } from 'react'

import { ColorStyleSet, ColorStyleName } from '@/types'

type ColorStyleDisplayProps = {
  style: ColorStyleSet
}

const styleImageMap: Record<ColorStyleName, string> = {
  basic: '/images/themes/Basic.png',
  dark: '/images/themes/Dark.png',
  pastel: '/images/themes/Pastel.png',
}

export const ColorStyleDisplay: FC<ColorStyleDisplayProps> = ({ style }) => {
  return (
    <>
      <div className='mb-2.5 flex items-center justify-between gap-5'>
        <h2 className={`text-base font-semibold leading-6 text-accent-dark`}>
          {style.title}
        </h2>
      </div>
      <div className='w-full rounded-[6px] shadow-box'>
        <img
          src={styleImageMap[style.name]}
          alt={`color style ${style.name}`}
        />
      </div>
    </>
  )
}
