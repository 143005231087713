import {
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Card } from '@/components/Card'
import { TextInput } from '@/components/form/TextInput'
import { ColorStyleDisplay } from '@/components/ColorStyleDisplay'
import { TextArea } from '@/components/form/TextArea'
import {
  ColorStyleName,
  ShopConfigurationFormValues,
  shopCreationColorStyles,
} from '@/types'
import { ErrorDescription } from '@/components/form/ErrorDescription'
import { shopInformationStore } from '@/lib/LocalStore'
import { AppState } from '@/lib/AppState'
import { ExamplePrompt, ExamplePrompts } from '@/components/ExamplePrompts'
import { validateShopName } from '@/lib/helper'
import { ImproveInputModal } from '@/components/modals/ImproveInputModal'

export default function Home() {
  const [products, setProducts] = useState<string[]>([])
  const [showProductsError, setShowProductsError] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [showImproveInputModal, setShowImproveInputModal] = useState(false)

  const productInputRef = useRef<HTMLTextAreaElement>(null)
  const shopDescriptionRef = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const onSubmit = (data: ShopConfigurationFormValues) => {
    if (products.length === 0) {
      setShowProductsError(true)
      return
    }

    const referralInput = document.querySelector<HTMLInputElement>(
      'input[name="referral"]',
    )

    let referralValue = ''
    if (referralInput !== null) {
      console.log(`Rewardful referral id: ${referralInput.value}`)
      referralValue = referralInput.value
    }

    setSubmitted(true)
    const url = new URL(window.location.href)
    shopInformationStore.set({
      ...data,
      products,
      referral: referralValue,
      source_referrer: url.searchParams.get('source_referrer') || '',
      source: url.searchParams.get('source') || '',
      source_medium: url.searchParams.get('source_medium') || '',
      source_campaign: url.searchParams.get('source_campaign') || '',
    })

    router.push('/create-shop')
  }

  const onEnterProduct: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    if (event.key !== 'Enter') return
    if (products.length >= 3) return

    event.preventDefault()

    const product = event.currentTarget.value.trim()
    if (product) {
      setProducts(products => products.concat(product))
    }

    event.currentTarget.value = ''
    productInputRef?.current?.focus()
  }

  const onClickEnterProduct: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()

    if (!productInputRef.current?.value) return

    const product = productInputRef.current?.value.trim()
    if (product) {
      setProducts(products => products.concat(product))
    }
    productInputRef.current.value = ''
    productInputRef.current.focus()
  }

  const deleteProduct = (product2delete: string) => {
    setProducts(products =>
      products.filter(product => product != product2delete),
    )
  }

  useEffect(() => {
    const shouldRedirect = AppState.shouldRedirect()
    if (shouldRedirect) {
      router.push(shouldRedirect)
    } else {
      AppState.set('collecting_information')
    }

    const shouldShowImproveInputHint = !!router?.query?.improveInput ?? false

    setShowImproveInputModal(shouldShowImproveInputHint)
  }, [router])

  useEffect(() => {
    const productInputs = shopInformationStore.get()?.products
    if (productInputs) setProducts(productInputs)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ShopConfigurationFormValues>({
    defaultValues: shopInformationStore.get(),
  })

  return (
    <div className='rounded-[40px] bg-[#3C4570]/[.22] px-5 py-10 backdrop-blur-[50px] md:py-[60px] lg:px-10 mx-auto max-w-[1000px]'>
      <div className='mx-auto w-full max-w-[760px]'>
        <h1 className='text-center font-bold mb-[10px] leading-[26.5px] text-[22px] md:text-[44px] md:leading-[53px]'>
          Create an Online Store in Seconds <br className='md:hidden' />
          with the <span className='text-[#5B7FFF]'>Power of AI</span>
        </h1>
        <h2 className='max-w-[700px] mx-auto text-center font-light opacity-80 mb-[30px] md:mb-[60px] text-[14px] md:text-[20px] md:leading-[24px] '>
          Start building your fully functional store today and bring your
          e-commerce dreams to life.
        </h2>

        {showImproveInputModal && (
          <ImproveInputModal onClose={() => setShowImproveInputModal(false)} />
        )}
        <form onSubmit={handleSubmit(onSubmit)} data-rewardful>
          <div className='mb-[30px] md:mb-[60px]'>
            <label htmlFor='shop-business' className='mb-2.5 block text-base'>
              What's the name of your online store?
            </label>

            <div className='relative'>
              <TextInput
                {...register('shopName', {
                  required: true,
                  validate: shopName => validateShopName(shopName),
                })}
                autoFocus
                id='shop-business'
                placeholder='e.g. Handmade Jewelry by Rosie'
                data-hj-allow
              />
              {errors.shopName && (
                <ErrorDescription>
                  Need a shop or business name for good results (min. 5
                  alphanumeric characters)
                </ErrorDescription>
              )}
            </div>
          </div>
          <div className='mb-[30px] md:mb-[60px]'>
            <label className='mb-2.5 block text-base'>
              Each color scheme created is unique - choose what suits you best.
            </label>
            <div className='grid grid-cols-1 gap-2.5 sm:grid-cols-2 md:grid-cols-3'>
              {Object.entries(shopCreationColorStyles).map(
                ([name, style], index) => {
                  return (
                    <div key={index}>
                      <input
                        type='radio'
                        id={name}
                        value={name}
                        defaultChecked={index == 0}
                        className='peer hidden'
                        {...register('design', { required: true })}
                      />
                      <div
                        onClick={() =>
                          setValue('design', name as ColorStyleName)
                        }
                        className={`rounded-[6px] bg-white/80 p-2.5 ring-inset transition-all peer-checked:ring-2 peer-checked:ring-accent-blue cursor-pointer`}
                      >
                        <ColorStyleDisplay style={style} />
                      </div>
                    </div>
                  )
                },
              )}
              {errors.design && (
                <ErrorDescription>Select a design scheme</ErrorDescription>
              )}
            </div>
          </div>
          <div className='mb-[30px] md:mb-[60px]' ref={shopDescriptionRef}>
            <label htmlFor='shop-describe' className='mb-2.5 block text-base'>
              Describe your shop in two to three sentences.
            </label>
            <TextArea
              {...register('shopDescription', {
                required: true,
              })}
              autoFocus
              placeholder='e.g. Handmade Jewelry is an online store that offers one-of-a-kind jewelry made by independent artisans using various materials.'
              id='shop-describe'
              data-hj-allow
            />

            {errors.shopDescription && (
              <ErrorDescription>
                Need a description for good results
              </ErrorDescription>
            )}
          </div>
          <div className='mb-[30px] md:mb-[60px]'>
            <label htmlFor='products-desc' className='mb-2.5 text-lg md:block'>
              Let's get the party started!
            </label>
            <p className='mb-2.5 text-base text-[#f5f7f8]'>
              Describe up to three of your most awesome products and get ready
              to see them come to life.
            </p>
            <div className='flex flex-col gap-2.5 sm:flex-row'>
              <div className='relative w-full'>
                <TextArea
                  autoFocus
                  placeholder='e.g. A handmade silver necklace adorned with turquoise stones. It is 18 inches long. Price: 19,99$.'
                  onKeyUp={onEnterProduct}
                  id='products-desc'
                  ref={productInputRef}
                  onChange={() => setShowProductsError(false)}
                  data-hj-allow
                />
                {showProductsError && (
                  <ErrorDescription>
                    Need a product for good results
                  </ErrorDescription>
                )}
              </div>

              <button
                className={`block h-[46px] w-full rounded-[6px] bg-[#0021B5] px-10 py-2.5 text-lg font-semibold leading-6 text-white sm:w-[130px] ${
                  submitted || products.length == 3 ? 'opacity-50' : ''
                }`}
                type='submit'
                disabled={submitted || products.length >= 3}
                onClick={onClickEnterProduct}
              >
                Add
              </button>
            </div>

            <div className='mt-2.5 space-y-2.5'>
              {products.map((product, index) => (
                <Card key={index}>
                  <p>{product}</p>
                  <button
                    onClick={e => {
                      e.preventDefault()
                      deleteProduct(product)
                    }}
                  >
                    <svg
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6 18.2682L18 6.26819M6 6.26819L18 18.2682'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                </Card>
              ))}
            </div>
          </div>
          <ExamplePrompts
            className='text-center mb-[20px]'
            onFillPromptWithExamples={(example: ExamplePrompt) => {
              setProducts(example.productDescriptions)
              setValue('shopDescription', example.shopDescription)

              shopDescriptionRef.current?.scrollIntoView({
                behavior: 'smooth',
              })
            }}
          >
            Not sure?{' '}
            <span className='cursor-pointer text-accent-blue transition-all hover:text-blue-700 hover:underline'>
              Try a few examples
            </span>
          </ExamplePrompts>
          <button
            className={`flex w-full items-center justify-center gap-2 rounded-[6px] ${
              submitted || products.length == 0 ? 'opacity-50' : ''
            } bg-[#0021B5] px-10 py-[11px] text-lg font-semibold text-white`}
            type='submit'
            disabled={submitted || products.length == 0}
          >
            <svg
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5 3.26819V7.26819M3 5.26819H7M6 17.2682V21.2682M4 19.2682H8M13 3.26819L15.286 10.1252L21 12.2682L15.286 14.4112L13 21.2682L10.714 14.4112L5 12.2682L10.714 10.1252L13 3.26819Z'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Create Store
          </button>
        </form>
      </div>
    </div>
  )
}
