import Link from 'next/link'
import { FC, PropsWithChildren, useEffect } from 'react'

export type ModalProps = PropsWithChildren & {
  onClose: () => void
  headline?: string
  hideTerms?: boolean
}

export const Modal: FC<ModalProps> = ({
  children,
  onClose,
  headline,
  hideTerms,
}) => {
  useEffect(() => {
    const bodyElement = document.querySelector('body')
    if (!bodyElement) {
      return
    }
    bodyElement.classList.add('scroll-lock')
    return () => {
      bodyElement.classList.remove('scroll-lock')
    }
  })

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm bg-[#151618]/[.52]'>
      <div className='relative w-full max-w-[520px] max-h-full mx-auto mt-[45px] md:mt-[5%] mb-[50px]'>
        <div className='relative bg-white rounded-[40px] shadow'>
          <div className='flex items-start justify-between p-4 relative'>
            <button
              type='button'
              className='absolute top-[-50px] right-[-5px] md:top-[-40px] md:right-[-20px] text-white bg-[#c4c4c4] hover:bg-gray-200 hover:text-gray-900 rounded-[100px] text-sm p-1.5 ml-auto inline-flex items-center'
              onClick={onClose}
            >
              <svg
                aria-hidden='true'
                className='w-7 h-7'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <span className='sr-only'>Close modal</span>
            </button>
            <div className='py-[20px] px-[5px] md:py-[45px] md:px-[60px] w-full text-center'>
              <img
                src='/images/Logo-blue.svg'
                alt='Branchbob'
                className=' mx-auto'
              />

              <p className='text-accent-dark text-xl text-base font-medium mt-[28px] md:mb-[20px]'>
                {headline || 'Save your shop and start selling today 🚀'}
              </p>

              {children}

              {!hideTerms && (
                <p className='text-accent-dark font-light px-[10px] text-[14px]'>
                  By continuing, you agree to our{' '}
                  <Link
                    href='https://www.branchbob.com/terms'
                    target='_blank'
                    className='font-grotesk text-accent-blue transition-all hover:text-blue-700 hover:underline'
                  >
                    Terms
                  </Link>{' '}
                  and{' '}
                  <Link
                    href='https://www.branchbob.com/privacy'
                    target='_blank'
                    className='font-grotesk text-accent-blue transition-all hover:text-blue-700 hover:underline'
                  >
                    Privacy Policy
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
