import { forwardRef } from 'react'
import cx from 'classnames'

type TextInputProps = JSX.IntrinsicElements['input']

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props, ref) {
    props = {
      ...props,
      className: cx(
        'block h-[46px] w-full rounded-[6px] bg-white p-2.5 text-base text-accent-dark focus:border-accent-blue focus:outline-accent-blue',
        props.className,
      ),
    }

    return <input type='text' ref={ref} {...props} />
  },
)
