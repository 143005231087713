import { forwardRef } from 'react'
import cx from 'classnames'

type TextAreaProps = JSX.IntrinsicElements['textarea']

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    props = {
      ...props,
      className: cx(
        'block h-[92px] md:h-[70px] w-full rounded-[6px] bg-white p-2.5 text-base text-accent-dark focus:border-accent-blue focus:outline-accent-blue',
        props.className,
      ),
    }

    return <textarea ref={ref} {...props} />
  },
)
