import { FC, PropsWithChildren, useState } from 'react'

export type ExamplePrompt = {
  shopDescription: string
  productDescriptions: string[]
}

export type ExamplePromptsProps = PropsWithChildren & {
  onFillPromptWithExamples: (example: ExamplePrompt) => void
  className?: string
}

const examplePrompts: ExamplePrompt[] = [
  {
    shopDescription:
      'Roast & Grind is a specialty coffee bean online store that offers premium quality coffee beans sourced from around the world, roasted fresh in small batches, and delivered right to your doorstep. We are passionate about bringing you the best coffee experience possible, and our expert team is always on hand to help you choose the perfect roast for your taste buds.',
    productDescriptions: [
      'Ethiopian Yirgacheffe - Light Roast',
      'Brazilian Santos - Medium Roast',
      'Sumatra Mandheling - Dark Roast',
    ],
  },
  {
    shopDescription:
      'NutriFit Essentials is an online store that offers a variety of high-quality food supplements to support a healthy lifestyle.',
    productDescriptions: [
      'Omega-3 Fish Oil Softgels',
      'Probiotic Digestive Support Capsules',
      'Vegan Plant-Based Protein Powder',
    ],
  },
  {
    shopDescription:
      'Olio Elegante offers a curated selection of the finest and most flavorful olive oils from around the world. Our oils are carefully selected for their unique flavor profiles and high-quality standards.',
    productDescriptions: [
      'Organic Extra Virgin Olive Oil from Italy',
      'Cold-Pressed Olive Oil from Greece',
      ' Flavored Olive Oil Gift Set from Spain',
    ],
  },
]

export const ExamplePrompts: FC<ExamplePromptsProps> = ({
  children,
  onFillPromptWithExamples,
  className,
}) => {
  const [exampleIndex, setExampleIndex] = useState(0)

  return (
    <div
      className={className}
      onClick={() => {
        const example = examplePrompts[exampleIndex]
        onFillPromptWithExamples(example)

        const newIndex = (exampleIndex + 1) % examplePrompts.length
        setExampleIndex(newIndex)
      }}
    >
      {children}
    </div>
  )
}
