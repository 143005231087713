import { FC } from 'react'

import { Modal, ModalProps } from './Modal'

export type ImproveInputModalProps = ModalProps

export const ImproveInputModal: FC<ImproveInputModalProps> = ({ onClose }) => {
  return (
    <Modal onClose={onClose} hideTerms={true} headline={'Insufficient input'}>
      <p className='text-left text-accent-gray text-[18px] text-base font-light mt-[20px] mb-[20px]'>
        Unfortunately, we were unable to create your store based on the provided
        information. Please review and enhance your input. To ensure a
        successful store creation, kindly follow these guidelines:
      </p>
      <ul className='list-disc text-left text-accent-gray text-[18px] text-base font-light mt-[20px] mb-[20px]'>
        <li>
          Add your products one by one by clicking on the
          <button
            className={`h-[46px] w-full rounded-[6px] bg-[#0021B5] px-6 my-2 mr-2 py-2.5 text-lg font-semibold leading-6 text-white sm:w-[80px]`}
            type='submit'
          >
            Add
          </button>
          -button.
        </li>
        <li>Use a simplified writing style for your product descriptions.</li>
        <li>Include only one price per product.</li>
      </ul>
      <p className='text-left text-accent-gray text-[18px] text-base font-light mt-[20px] mb-[20px]'>
        If you're unsure about any step, feel free to click on the 'Try a Few
        Examples' link located above the Create Store button. It will provide
        you with some useful examples to assist you during the process.
      </p>
      <button
        className='mb-5 mx-auto block w-full rounded-[6px] text-[#0021B5] text-l font-semibold'
        onClick={onClose}
      >
        Close
      </button>
    </Modal>
  )
}
