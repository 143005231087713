import {
  ShopConfigurationFormValues,
  ShopCreationResponseValues,
} from '@/types'

import { PageStates } from './AppState'

export default class LocalStore<Type> {
  public context: string

  constructor(context: string) {
    this.context = context
  }

  public static get<Type>(
    key: string,
    fallback: Type | undefined = undefined,
  ): Type | undefined {
    try {
      const serializedState = localStorage.getItem(key)

      if (!serializedState) return fallback

      return JSON.parse(serializedState)
    } catch (e) {
      return fallback
    }
  }

  public static set<Type>(key: string, state: Type) {
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem(key, serializedState)
    } catch (e) {
      // Ignore
    }
  }

  public static delete(key: string) {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      // Ignore
    }
  }

  public delete() {
    return LocalStore.delete(this.context)
  }

  public get(fallback: Type | undefined = undefined): Type | undefined {
    return LocalStore.get<Type>(this.context, fallback)
  }

  public set(state: Type) {
    return LocalStore.set<Type>(this.context, state)
  }
}

export const jobIdStore = new LocalStore<string | undefined>('jobId')
export const pageStateStore = new LocalStore<PageStates>('pageState')
export const selectedAppIdStore = new LocalStore<string | 'ai_flex'>(
  'selectedAppId',
)
export const shopInformationStore = new LocalStore<ShopConfigurationFormValues>(
  'shopConfiguration',
)
export const shopCreationResponseStore =
  new LocalStore<ShopCreationResponseValues>('shopCreationResponse')
