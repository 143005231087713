import { NotifiableError } from '@bugsnag/js'

import { ErrorReporter } from './ErrorReporter'
import { pageStateStore } from './LocalStore'

export type PageStates =
  | 'error'
  | 'initial'
  | 'collecting_information'
  | 'creating_store'
  | 'creating_store_waiting_for_finish'
  | 'preview_store'
  | 'user_updated'
  | 'payment_started'
  | 'payment_finished'

const log = (msg: string) => {
  console.log(`AppState: ${msg}`)
}
export class AppState {
  static set(state: PageStates, msg: NotifiableError = '') {
    if (this.state() == state) {
      log(`transitioning to same page state: ${state} ${msg}`)
      return false
    }

    if (
      this.state() == 'creating_store_waiting_for_finish' &&
      ['creating_store'].includes(state)
    ) {
      log(`preventing page state ${state} as creation already started`)
      return false
    }

    if (
      this.state() == 'payment_started' &&
      !['payment_finished', 'error'].includes(state)
    ) {
      log(`preventing page state ${state} as payment already started`)
      return false
    }

    if (
      this.state() == 'user_updated' &&
      !['payment_started', 'payment_finished', 'error'].includes(state)
    ) {
      log(`preventing page state ${state} as the user was already updated`)
      return false
    }

    if (state == 'error' && msg) ErrorReporter.notify(msg)
    else ErrorReporter.setContext(state)

    log(`setting page state: ${state} ${msg ? '-> ' + msg : ''}`)
    pageStateStore.set(state)

    return true
  }

  static state() {
    return pageStateStore.get() || 'initial'
  }

  static shouldRedirect(): string | undefined {
    if (
      ['payment_started', 'user_updated', 'preview_store'].includes(
        AppState.state(),
      )
    )
      return '/shop-preview'

    if (
      ['creating_store', 'creating_store_waiting_for_finish'].includes(
        AppState.state(),
      )
    )
      return '/create-shop'
  }
}
