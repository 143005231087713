import { FC, ReactNode } from 'react'

import { User } from '@/worker/apitypes'

export type FCC<P = object> = FC<P & { children: ReactNode | ReactNode[] }>
export type Stylable<T = object> = FCC<T & { className?: string }>

export type ShopCreationPostValues = {
  shopName: string
  shopDescription: string
  products: string[]
  design: ColorStyleName
  referral: string
  source_referrer: string
  source: string
  source_medium: string
  source_campaign: string
}

export type ShopConfigurationFormValues = {
  shopName: string
  shopDescription: string
  product: string // we just collect the current one here
  products: string[]
  design: ColorStyleName
  referral: string
  source_referrer: string
  source: string
  source_medium: string
  source_campaign: string
}

export type ShopCreationResponseValues = {
  token: string
  storeUrl: string
  storeId: string
  storeName: string
  encryptedUserId: string
}

export type ShopCreationErrorResponse = {
  error: string
}

export type ShopCreationInProgressResponse = {
  running: boolean
}

export type UserInformationFormValues = {
  encryptedUserId: string // hmac encrypted
  email: User['email']
  password: User['password']
}

export type ColorStyleName = 'basic' | 'dark' | 'pastel'

export type ColorStyleSet = {
  title: string
  name: ColorStyleName
}

export const shopCreationColorStyles: Record<ColorStyleName, ColorStyleSet> = {
  basic: {
    title: 'Basic',
    name: 'basic',
  },
  pastel: {
    title: 'Pastel',
    name: 'pastel',
  },
  dark: {
    title: 'Dark',
    name: 'dark',
  },
}
