import cx from 'classnames'

import { Stylable } from '@/types'

export const Card: Stylable = ({ children, className }) => (
  <div
    className={cx(
      'flex items-center justify-between gap-10 rounded-[6px] border border-white px-2.5 py-[11px] text-base',
      className,
    )}
  >
    {children}
  </div>
)
